import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';

import * as fromStore from '../store';
import { Observable, of } from 'rxjs';
import { NewsOverviewComponent } from '../containers/news-overview/news-overview.component';

@Injectable({ providedIn: 'root' })
export class NewsGuard {
  constructor(private store: Store<fromStore.ContentState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkStore(route).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkDeactivateStore(currentRoute).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkDeactivateStore(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(fromStore.getNewsLoaded).pipe(
      tap(loaded => {
        if (loaded) {
          this.store.dispatch(fromStore.ClearNews());
        }
      }),
      filter(loaded => !loaded),
      take(1)
    );
  }

  checkStore(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(fromStore.getNewsLoaded).pipe(
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(fromStore.LoadNews({ top: undefined, skip: undefined, category: route?.queryParams['category'] }));
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
